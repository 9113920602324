
.entrees{
    
    border: solid skyblue 5px;
    border-radius: 5px;
    

}
.group{
    margin-top: 10px;
}
.mySwiper{
    margin-bottom: 30px;
}
.lab{
    margin-top: 15px;
    font-weight: bolder;
    font-family: cursive;
}













































/* .swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body{
    background: #eee;
}

.swiper {
    width: 100%;
    height: 300px;
    margin: 20px 0;

} */