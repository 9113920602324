// .more-button{
//     background-color: green;
// }



@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// * { box-sizing: border-box; }

$body-bg: #84a0f4;
$button-bg: #5c67ff;
$list-bg: #fff;
$text-color: #1c3991;
$text-color-hover: #5c67ff;
$menu-icon-transition: transform 300ms cubic-bezier(0.52, -0.80, 0.52, 0.52);

// body {
//   width: 100%;
//   min-height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: $body-bg;
//   background-image: linear-gradient(to top, #84a0f4 0%, #c2e9fb 100%);
//   font-family: 'Montserrat', sans-serif;
// }

.list-container {
  // position: relative;
  
  &.active {
    .more-button-list {
       opacity: 1;
       transform: scale(1);
    }
    
    .more-button-list-item {
      animation: fadeInItem .6s .2s forwards;
      
      &:nth-child(2) { animation-delay: .4s; }
      &:nth-child(3) { animation-delay: .6s; }
      &:nth-child(4) { animation-delay: .8s; }
    }
    
    .more-button {
      animation: onePulse .6s forwards linear;
    }
    
    .menu-icon-wrapper {
      transform: rotate(-45deg);
    }
    
    .menu-icon-line {
      &.first {
        transform: rotate(-90deg) translateX(1px);
      }
      
      &.last {
        transform: rotate(-90deg) translateX(-1px);
      }
    }
  }
}

.more-button {
  // background-color: $button-bg;
  // background-color: #4bb8ff;
  background-color: rgb(80, 200, 255);
  // background-color: #1B71A1;
  // background-color: #a175f2;
  box-shadow: 0px 0px 0px 4px rgba(92,103,255,0.3);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  z-index: 2;
  
  
  &:hover, &:focus {
    box-shadow: 0px 0px 0px 8px rgba(92,103,255,0.3);
    // background-color: darken($button-bg,4%);
    // background-color: #4bb8ff;
  background-color: #2c84b3;
  // background-color: #b89bef;

  }
  
  &:focus { outline: 0; }
   
  &-list {
    background-color: $list-bg;
    border-radius: 8px;
    list-style-type: none;
    width: 140px;
    height: 170px;
    box-shadow: 0px 0px 4px 4px rgba(150, 157, 249, 0.16);
    padding: 0;
    padding: 6px;
    position: absolute;
    right: 4px;
    top: 0px;
    bottom: 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: bottom right;
    transition : all .3s ease .1s;
    
    
    li { opacity: 0; }
  }
  
  &-list-item {
    display: flex;
    align-items: center;
    color: $text-color;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: .2s ease-in;
    transform: translatex(-10px);
    
    &:hover { color: $text-color-hover; }
    
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: calc(100% - 24px);
      left: 12px;
      bottom: 0;
      background-color: rgba(132, 160, 244, 0.1);
    }
    &:last-child:after { display: none; }
    
    svg {
      width: 18px;
      height: 18px;
    }
    
    span {
      display: inline-block;
      line-height: 20px;
      font-size: 14px;
      margin-left: 8px;
    }
  }
}

@keyframes onePulse {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(92,103,255,0.3);
  }
  
  50% {
    box-shadow: 0px 0px 0px 12px rgba(92,103,255,0.1);
  }
  
  100% {
    box-shadow: 0px 0px 0px 4px rgba(92,103,255,0.3);
  }
}

@keyframes fadeInItem {
  100% {
    transform: translatex(0px);
    opacity: 1;
  }
}

.socials {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
}

.social-link {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-right: 12px;
}

.menu-icon-wrapper {
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;
}

.menu-icon-line {
  background-color: #fff;
  border-radius: 2px;
  width: 100%;
  height: 2px;
  
  &.half { width: 50%;}
  
  &.first {
    transition: $menu-icon-transition;
    transform-origin: right;
  }
  
  &.last {
    align-self: flex-end;
    transition: $menu-icon-transition;
    transform-origin: left;
  }
}