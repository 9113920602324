@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import "./Homesz.scss";
.homes {
  width: 100%;
  /* height: 100%; */
  padding: 20px;
  background-color: aliceblue;
  /* min-height: 100%; */
  min-height: 100vh;
}
.divcom {
  min-height: 100vh;
}
.alice {
  background-color: aliceblue;
  min-height: 100%;
}
.hom {
  background-color: aliceblue;
  font-family: Cool jazz, Arial, Helvetica, sans-serif;
}
.hom0 {
  width: 100%;
  padding: 20px;
  background-color: aliceblue;
  font-family: Cool jazz, Arial, Helvetica, sans-serif;
}

.Titre1 {
  font-weight: bold;
  color: deepskyblue;
  text-align: center;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.Titre2 {
  font-family: Cool jazz;
  text-align: center;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.Titre22 {
  font-family: Cool jazz;
  text-align: center;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 80%;
}

.straze {
  color: rgb(121, 118, 118);
  font-weight: 600;
}

.aaa {
  height: 90px;
}
.div1 {
  background-color: white;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  min-height: 100vh;
}

.div2 {
  background-color: white;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-top: 25px;
}
.add1 {
  color: deepskyblue;
}
.add2 {
  color: #92949c;
}
.add3 {
  font-family: Cool jazz;
}
.add4 {
  color: #21b259;
}
.add5 {
  color: #b22121;
  font-size: 18px;
}
.adddate {
  color: #b021b2;
}
.adin {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.Item1 {
  font-family: Cool jazz;
  color: deepskyblue;
  text-align: center;
  border-bottom: 1px solid rgb(121, 118, 118);
}

.para {
  margin-top: 5px;
  background-color: aliceblue;
  border-start-start-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 30%;
}

.Itemsv {
  font-family: Cool jazz, Arial, Helvetica, sans-serif;
  font-size: large;
  padding: auto;
}
.nereide {
  font-family: Cool jazz, Arial, Helvetica, sans-serif;
}
.cepheide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #92949c;
}
.itemlv {
  font-size: small;
  margin: auto;
  width: 100%;
}
.grid1 {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 500px;
}
.silk {
  margin: 0 0 0 0;
  text-align: left;
  color: rgb(121, 118, 118);
  font-weight: 600;
}
.dril {
  height: 250px;
  min-width: 50%;
  max-width: 50%;
  /* min-width: 130px;
    max-width: 200px; */
}

.empty_full {
  font-size: 1em;
  color: red;
  /* margin-top: 10px; */
  transform-origin: 50% 50%;
  animation: 1s jaja;
}
.failed_full {
  font-size: 1.2em;
  color: red;
  /* margin-top: 10px; */
  transform-origin: 50% 50%;
  animation: 1s jaja;
}
.userExistAlreadyy {
  /* color: red;
    font-size: 1.2em;
    animation: videe 2s forwards; */
  font-size: 1.2em;
  color: red;
  margin-top: 10px;
  transform-origin: 50% 50%;
  animation: 1s jaja;
}
.kika {
  color: red;
  position: absolute;
  right: 20px;
  transform-origin: 50% 50%;
  animation: 1s jaja;
}
.conter {
  width: 100%;
}
.conter1 {
  width: 80%;
}
.famit {
  font-family: Arial, "Times New Roman", Times, serif, sans-serif;
}
.ima1 {
  width: 20%;
}
.ima2 {
  width: 20%;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
}
.pp {
  width: 80%;
}
.pp2 {
  width: 70%;
  align-items: left;
  justify-content: left;
}
.tailo {
  font-size: 24px;
}
/* .guerre{
    height: 100px;
  } */
.eyeview {
  position: relative;
  top: -35%;
  left: 90%;
  z-index: 100;
  /* left: 50px; */
}
.badgecor {
  position: relative;
  top: -30%;
  left: 60%;
}
.chooseimg {
  position: absolute;
  top: 72%;
  left: 70%;
}

.slidenav {
  height: 700px;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}
@keyframes videe {
  0% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(10px);
  }
  40% {
    transform: translateX(30px);
  }
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes jaja {
  0% {
    transform: translate(0px, 0px);
  }

  10% {
    transform: translate(-10px, 0px);
  }

  20% {
    transform: translate(10px, 0px);
  }

  30% {
    transform: translate(-10px, 0px);
  }

  40% {
    transform: translate(10px, 0px);
  }

  50% {
    transform: translate(-10px, 0px);
  }

  60% {
    transform: translate(10px, 0px);
  }

  70% {
    transform: translate(-10px, 0px);
  }

  80% {
    transform: translate(10px, 0px);
  }

  90% {
    transform: translate(-10px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.loader {
  position: absolute;
  width: 54px;
  height: 54px;
  margin: 0 40%;
  border-radius: 10px;
}

.loader div {
  width: 8%;
  height: 24%;
  background: rgb(128, 128, 128);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade458 1s linear infinite;
}

.box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}

.neumorph {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  margin: 30px;
  width: 120px;
  background: #ffffff;
  /* background: #ebeef1; */
  cursor: pointer;
  border: none;
  border-radius: 50px;
  color: #ed0000;
  box-shadow: 10px 10px 10px -1px rgba(10, 99, 169, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.7);
}

.neumorph1 {
  color: #00a037;
  box-shadow: inset 10px 10px 10px -1px rgba(10, 99, 169, 0.16),
    inset -10px -10px 10px -1px rgba(255, 255, 255, 0.7);
}

.custom-class1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
}


.lap{
  background-color: #0080ff;
  padding: 15px;
  /* position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%; */
  font-family: "Roboto Mono",monospace;
  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}
::-webkit-calendar-picker-indicator{
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5D3FD3;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
 }
 
 .three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
 }
 
 .three-body__dot:after {
  content: '';
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
 }
 
 .three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
 }
 
 .three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite
     calc(var(--uib-speed) * -0.15) ease-in-out;
 }
 
 .three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
 }

 /*  */
 
 .three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
 }
 
 @keyframes spin78236 {
  0% {
   transform: rotate(0deg);
  }
 
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes wobble1 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(-66%) scale(0.65);
   opacity: 0.8;
  }
 }
 
 @keyframes wobble2 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(66%) scale(0.65);
   opacity: 0.8;
  }
 }

 .progress-container {
  width: 100%;
  background-color: #f1f1f1;
  margin-top: 10px;
  border-radius: 4px;
  height: 10px;
}

.progress-barrrs {
  width: 0;
  height: 100%;
  background: #4caf50;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.step-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.step {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
}

.step.active {
  color: #4caf50;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: .9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* loader *************************************************************************/

.pl {
  width: 6em;
  height: 6em;
}

.pl__ring {
  animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: #f42f25;
}

.pl__ring--b {
  animation-name: ringB;
  stroke: #f49725;
}

.pl__ring--c {
  animation-name: ringC;
  stroke: #255ff4;
}

.pl__ring--d {
  animation-name: ringD;
  stroke: #f42582;
}

/* Animations */
@keyframes ringA {
  from, 4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%, 54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%, to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@keyframes ringB {
  from, 12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%, 62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%, to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%, 58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {
  from, 8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%, 50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%, to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}
 
/* .swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body{
    background: #eee;
}

.swiper {
    width: 100%;
    height: 300px;
    margin: 20px 0;

}


.Div1 {

    height    : 200px;
    width     : 100%;
   
    margin: 0px;
    margin-bottom: 10px;
    

}
.Div2 {

    height    : 150px;
    background: rgb(204, 77, 60);
    width     : 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0px;
}
.Div3{
    height: 60px;
    width     : 100%;
    text-align: center;
    align-items:flex-start;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 0px;
    margin-bottom: 30px;
}
.articles{
    color: azure;
    background-color: aquamarine;
    height: 105px;
    width: 30%;
}
.subtitle {
max-height: 25px;
word-wrap: none;
overflow-y:scroll ;
color: rgb(39, 37, 37)


}
.card{
    
    text-align: center;
    width: 70%;
    margin-bottom: 50px;
    box-shadow: 1px 3px 2px black ;  
}
.cardcontent{
    text-align: center;
    
    height: 65px;
    color: deepskyblue;
    
    
}
.imga {
    height: 100px;
    border-radius: 5px;
    border-bottom: 1px solid #0a0909;

}



.title{
    color: deepskyblue;
    font-weight: bold;
    font-size: medium;
    
}
.straze{
    font-weight: bolder;


}
.subtitle{
    margin-left: 2%;
    font-weight: 100;
    font-size: small;
}

 */
